const INITIAL_SORTING = {
  type: "name",
  order: "asc",
}

const STATUS_ORDER = {
  available: 1,
  reserved: 2,
  sold: 3,
}

const sortApartments = (
  apartments,
  options = {
    ...INITIAL_SORTING,
  }
) => {
  if (!(apartments?.length > 0)) return undefined

  if (!options?.type) return apartments

  const sortOrder = (a, b, order) =>
    order ?? options.order === "asc"
      ? a !== null && b !== null
        ? a > b
          ? 1
          : -1
        : b === null && a
        ? -1
        : 1
      : a < b
      ? 1
      : -1

  return apartments.sort((a, b) => {
    const [a_prefix, a_number, a_subnumber] = a.title
      .match(/([A-Z]) (\d+)\.(\d+)/)
      .slice(1)
    const [b_prefix, b_number, b_subnumber] = b.title
      .match(/([A-Z]) (\d+)\.(\d+)/)
      .slice(1)

    const sortByName = order => {
      return a_prefix !== b_prefix
        ? order === "asc"
          ? a_prefix.localeCompare(b_prefix)
          : b_prefix.localeCompare(a_prefix)
        : a_number !== b_number
        ? sortOrder(parseInt(a_number), parseInt(b_number))
        : sortOrder(parseInt(a_subnumber), parseInt(b_subnumber))
    }

    if (options.type === "name") {
      return sortByName(options.order)
    } else {
      const byName = sortByName("asc")

      if (options.type === "rooms") {
        const a_rooms = a.acfApartment.roomsnumber
        const b_rooms = b.acfApartment.roomsnumber

        return a_rooms !== b_rooms ? sortOrder(a_rooms, b_rooms) : byName
      }

      if (options.type === "area") {
        const a_area = parseFloat(a.acfApartment.area)
        const b_area = parseFloat(b.acfApartment.area)

        return a_area !== b_area ? sortOrder(a_area, b_area) : byName
      }

      if (options.type === "floor") {
        const a_floor = a?.acfApartment?.floor ?? 0
        const b_floor = b?.acfApartment?.floor ?? 0

        return a_floor !== b_floor ? sortOrder(a_floor, b_floor) : byName
      }

      if (options.type === "status") {
        const a_status = STATUS_ORDER[a.acfApartment.status]
        const b_status = STATUS_ORDER[b.acfApartment.status]

        return a_status !== b_status ? sortOrder(a_status, b_status) : byName
      }
    }

    return 0
  })
}

export { INITIAL_SORTING, sortApartments }
