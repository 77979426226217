import "./styles.scss"

import React, { useState, useEffect, useRef } from "react"
import { withCookies } from "react-cookie"
import InputRange from "react-input-range"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"
import { Tooltip } from "react-tooltip"
import classnames from "classnames"

import DollyHousePopup from "./components/DollyHousePopup"
import { Switch } from "../components"

import { INITIAL_SORTING, sortApartments } from "./helpers"

import IcoView from "assets/icons/ico-view.svg"
import IcoEnvelope from "assets/icons/ico-envelope.svg"
import IcoArrow from "assets/icons/ico-apartments-arrow.svg"

const initialFilters = {
  buildings: [],
  buildingNumber: [],
  rooms: [],
  floors: [],
  status: [],
  promotion: false,
}

const SORT_OPTIONS = [
  {
    type: "name",
    label: "Nr lokalu",
  },
  {
    type: "rooms",
    label: "Pokoje",
  },
  {
    type: "area",
    label: "Metraż",
  },
  {
    type: "floor",
    label: "Piętro",
  },
  {
    type: null,
    label: "Ekspozycja",
  },
  {
    type: "status",
    label: "Status",
  },
  {
    type: null,
    label: "Rzut",
  },
  {
    type: null,
    label: "Kontakt",
  },
]

const getSavedFilters = (filters, options, isRange = false) => {
  const initialRange = isRange
    ? { value: { min: options.areaStart, max: options.areaEnd } }
    : options

  if (!filters || filters === "unset")
    return {
      ...initialFilters,
      ...initialRange,
    }

  const types = filters.split("&")
  const items = types.map(type => type.split("="))
  const objects = items.map(item => ({
    [item[0]]: item[1]
      .split(",")
      .map(value => (isNaN(parseInt(value)) ? value : parseInt(value))),
  }))

  const saved = {}

  objects.forEach(object => {
    if (isRange) {
      if (object.hasOwnProperty("areaStart")) {
        Object.assign(saved, {
          value: { ...saved?.value, min: object.areaStart[0] },
        })
      }

      if (object.hasOwnProperty("areaEnd")) {
        Object.assign(saved, {
          value: { ...saved?.value, max: object.areaEnd[0] },
        })
      }
    } else {
      if (
        !object.hasOwnProperty("areaStart") &&
        !object.hasOwnProperty("areaEnd")
      ) {
        Object.assign(saved, object)
      } else {
        const entries = Object.entries(object)

        return Object.assign(saved, {
          [entries[0][0]]: entries[0][1][0],
        })
      }
    }
  })

  if (isRange && saved?.value) {
    if (!saved.value.hasOwnProperty("min")) {
      Object.assign(saved, {
        value: {
          ...saved.value,
          min: initialRange.value.min,
        },
      })
    }

    if (!saved.value.hasOwnProperty("max")) {
      Object.assign(saved, {
        value: {
          ...saved.value,
          max: initialRange.value.max,
        },
      })
    }
  }

  return {
    ...(isRange ? {} : initialFilters),
    ...initialRange,
    ...saved,
  }
}

const Listing = ({ cookies, apartmentList, apartmentData, state }) => {
  const [dollyHouseImage, setDollyHouseImage] = useState(null)

  const highestArea = Math.ceil(
      Math.max(...apartmentList.map(item => item.acfApartment.area))
    ),
    lowestArea = Math.floor(
      Math.min(...apartmentList.map(item => item.acfApartment.area))
    )

  const [areaRange, setAreaRange] = useState({
    value: {
      min: state?.apartments?.areaStart ?? lowestArea,
      max: state?.apartments?.areaEnd ?? highestArea,
    },
  })

  const [sorting, setSorting] = useState(INITIAL_SORTING)

  const handleSorting = type =>
    setSorting(prevSorting => ({
      type,
      order:
        type === prevSorting.type
          ? prevSorting.order === "asc"
            ? "desc"
            : "asc"
          : type === "name" || type === "status"
          ? "asc"
          : "desc",
    }))

  const [apartmentFilter, setApartmentFilter] = useState({
    ...initialFilters,
    areaStart: lowestArea,
    areaEnd: highestArea,
    ...(state?.apartments ?? {}),
    promotion: state?.promotion ?? false,
  })

  let filteredApartments = apartmentList

  const setRange = value => {
    setAreaRange({ value })
    setApartmentFilter({
      ...apartmentFilter,
      areaStart: value.min,
      areaEnd: value.max,
    })
  }

  const setFloor = floorNumber => {
    if (apartmentFilter.floors.includes(floorNumber)) {
      let floors = apartmentFilter.floors
      const indexOfItem = apartmentFilter.floors.indexOf(floorNumber)

      floors.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, floor: floors })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        floors: [...apartmentFilter.floors, floorNumber],
      })
    }
  }

  const setRooms = roomNumber => {
    if (apartmentFilter.rooms.includes(roomNumber)) {
      let rooms = apartmentFilter.rooms
      const indexOfItem = apartmentFilter.rooms.indexOf(roomNumber)

      rooms.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, rooms: rooms })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        rooms: [...apartmentFilter.rooms, roomNumber],
      })
    }
  }

  const setBuildingNumber = buildingNumberItem => {
    if (apartmentFilter.buildingNumber.includes(buildingNumberItem)) {
      let buildingNumber = apartmentFilter.buildingNumber
      const indexOfItem =
        apartmentFilter.buildingNumber.indexOf(buildingNumberItem)

      buildingNumber.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, buildingNumber: buildingNumber })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        buildingNumber: [...apartmentFilter.buildingNumber, buildingNumberItem],
      })
    }
  }

  const setStatus = status => {
    if (apartmentFilter.status.includes(status)) {
      let statusList = apartmentFilter.status
      const indexOfItem = apartmentFilter.status.indexOf(status)

      statusList.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, status: statusList })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        status: [...apartmentFilter.status, status],
      })
    }
  }

  const apartmentsFiletering = () => {
    filteredApartments = apartmentList.filter(item => {
      const floor = parseInt(
          item.acfApartment.floor ? item.acfApartment.floor : 0
        ),
        room = parseInt(item.acfApartment.roomsnumber)

      if (
        item.acfApartment.area >= apartmentFilter.areaStart &&
        item.acfApartment.area <= apartmentFilter.areaEnd &&
        (apartmentFilter.floors.includes(floor) ||
          apartmentFilter.floors.length === 0) &&
        (apartmentFilter.rooms.includes(room) ||
          apartmentFilter.rooms.length === 0) &&
        (apartmentFilter.buildings.includes(item.acfApartment.buildingNumber) ||
          apartmentFilter.buildings.length === 0) &&
        (apartmentFilter.status.includes(item.acfApartment.status) ||
          apartmentFilter.status.length === 0) &&
        (apartmentFilter.buildingNumber.includes(
          item.acfApartment.buildingNumber
        ) ||
          apartmentFilter.buildingNumber.length === 0) &&
        !apartmentFilter.promotion
      ) {
        return item
      }
      return false
    })
  }

  const filtersDebounce = useRef(null)

  useEffect(() => {
    clearTimeout(filtersDebounce.current)

    filtersDebounce.current = setTimeout(() => {
      const filtersSelected = Object.fromEntries(
        Object.entries(apartmentFilter).filter(([key, value]) => {
          if (
            (key === "areaStart" && value !== lowestArea) ||
            (key === "areaEnd" && value !== highestArea) ||
            (key !== "areaStart" && key !== "areaEnd" && value.length > 0)
          ) {
            return true
          } else {
            return false
          }
        })
      )

      if (Object.keys(filtersSelected).length > 0) {
        let filters = ""

        Object.entries(filtersSelected).forEach(
          ([key, value], index) =>
            (filters += `${index !== 0 ? "&" : ""}${key}=${value}`)
        )

        cookies.set("cookie-filters-city-lobzow", filters, { path: "/" })
      } else {
        if (cookies.get("cookie-filters-city-lobzow") !== "unset") {
          cookies.set("cookie-filters-city-lobzow", "unset", { path: "/" })
        }
      }
    }, 200)
  }, [apartmentFilter, cookies, lowestArea, highestArea])

  useEffect(() => {
    if (cookies?.get("cookie-filters-city-lobzow") !== "unset") {
      setApartmentFilter(
        getSavedFilters(cookies.get("cookie-filters-city-lobzow"), {
          areaStart: lowestArea,
          areaEnd: highestArea,
        })
      )

      setAreaRange(
        getSavedFilters(
          cookies.get("cookie-filters-city-lobzow"),
          {
            areaStart: lowestArea,
            areaEnd: highestArea,
          },
          true
        )
      )
    }
  }, [cookies, highestArea, lowestArea])

  apartmentsFiletering()

  const sortedApartments = sortApartments(filteredApartments, sorting)

  return (
    <section className="apartments-listing">
      <div className="apartments-listing__filtering">
        <div className="container-fluid">
          <div className="apartments-listing__filtering-heading">
            <h3>Lista mieszkań</h3>
            <Switch {...apartmentData} />
          </div>
          <div className="row row--filters">
            <div className="apartments-listing__filtering-range">
              <p>Metraż</p>
              <InputRange
                allowSameValues={false}
                maxValue={highestArea}
                minValue={lowestArea}
                value={areaRange.value}
                onChange={value => setRange(value)}
              />
              <div className="input-range__legend">
                <p>
                  {areaRange.value.min} m<sup>2</sup>
                </p>
                <p>
                  {areaRange.value.max} m<sup>2</sup>
                </p>
              </div>
            </div>
            <div>
              <p>Ilość pokoi</p>
              <div className="apartments-listing__filtering-row">
                <button
                  onClick={() => setRooms(1)}
                  className={apartmentFilter.rooms.includes(1) ? "current" : ""}
                >
                  1
                </button>
                <button
                  onClick={() => setRooms(2)}
                  className={apartmentFilter.rooms.includes(2) ? "current" : ""}
                >
                  2
                </button>
                <button
                  onClick={() => setRooms(3)}
                  className={apartmentFilter.rooms.includes(3) ? "current" : ""}
                >
                  3
                </button>
                <button
                  onClick={() => setRooms(4)}
                  className={apartmentFilter.rooms.includes(4) ? "current" : ""}
                >
                  4
                </button>
              </div>
            </div>
            <div>
              <p>Klatka</p>
              <div className="apartments-listing__filtering-row">
                <button
                  onClick={() => setBuildingNumber("a")}
                  className={
                    apartmentFilter.buildingNumber.includes("a")
                      ? "current"
                      : ""
                  }
                >
                  A
                </button>
                <button
                  onClick={() => setBuildingNumber("b")}
                  className={
                    apartmentFilter.buildingNumber.includes("b")
                      ? "current"
                      : ""
                  }
                >
                  B
                </button>
                <button
                  onClick={() => setBuildingNumber("c")}
                  className={
                    apartmentFilter.buildingNumber.includes("c")
                      ? "current"
                      : ""
                  }
                >
                  C
                </button>
              </div>
            </div>
            <div>
              <p>Piętro</p>
              <div className="apartments-listing__filtering-row">
                <button
                  className={`ground ${
                    apartmentFilter.floors.includes(-1) ? "current" : ""
                  }`}
                  data-tooltip-id="tooltip-0"
                  onClick={() => setFloor(-1)}
                >
                  PN
                  <Tooltip id="tooltip-0" place="top" content="Parter niski" />
                </button>
                <button
                  className={`ground ${
                    apartmentFilter.floors.includes(0) ? "current" : ""
                  }`}
                  data-tooltip-id="tooltip-1"
                  onClick={() => setFloor(0)}
                >
                  PW
                  <Tooltip id="tooltip-1" place="top" content="Parter wysoki" />
                </button>
                <button
                  onClick={() => setFloor(1)}
                  className={
                    apartmentFilter.floors.includes(1) ? "current" : ""
                  }
                >
                  1
                </button>
                <button
                  onClick={() => setFloor(2)}
                  className={
                    apartmentFilter.floors.includes(2) ? "current" : ""
                  }
                >
                  2
                </button>
                <button
                  onClick={() => setFloor(3)}
                  className={
                    apartmentFilter.floors.includes(3) ? "current" : ""
                  }
                >
                  3
                </button>
                <button
                  onClick={() => setFloor(4)}
                  className={
                    apartmentFilter.floors.includes(4) ? "current" : ""
                  }
                >
                  4
                </button>
              </div>
            </div>
            <div className="d-md-none">
              <div className="apartments-listing__legend d-flex flex-wrap">
                <div className="apartments-listing__legend-status">
                  PN - Parter niski
                </div>
                <div className="apartments-listing__legend-status">
                  PW - Parter wysoki
                </div>
              </div>
            </div>
            <div>
              <p>Status</p>
              <div className="apartments-listing__filtering-row">
                <button
                  className={`available${
                    apartmentFilter.status.includes("available")
                      ? " current"
                      : ""
                  } `}
                  data-tooltip-id="tooltip-d"
                  onClick={() => setStatus("available")}
                >
                  D
                  <Tooltip id="tooltip-d" place="top" content="Dostępne" />
                </button>
                <button
                  className={`reserved${
                    apartmentFilter.status.includes("reserved")
                      ? " current"
                      : ""
                  } `}
                  data-tooltip-id="tooltip-z"
                  onClick={() => setStatus("reserved")}
                >
                  Z
                  <Tooltip id="tooltip-z" place="top" content="Zarezerwowane" />
                </button>
                <button
                  className={`sold${
                    apartmentFilter.status.includes("sold") ? " current" : ""
                  } `}
                  data-tooltip-id="tooltip-s"
                  onClick={() => setStatus("sold")}
                >
                  S
                  <Tooltip id="tooltip-s" place="top" content="Sprzedane" />
                </button>
              </div>
            </div>
            <div className="d-md-none">
              <div className="apartments-listing__legend d-flex flex-wrap">
                {["Dostępne", "Zarezerwowane", "Sprzedane"].map(
                  (status, index) => (
                    <div
                      key={index}
                      className={classnames(
                        "apartments-listing__legend-status",
                        {
                          available: status === "Dostępne",
                          reserved: status === "Zarezerwowane",
                        },
                        "d-flex align-items-center"
                      )}
                    >
                      <div className="apartments-listing__legend-status-icon d-flex align-items-center justify-content-center flex-shrink-0">
                        {status.charAt(0)}
                      </div>
                      - {status}
                    </div>
                  )
                )}
              </div>
            </div>
            <div>
              <p>Dodatkowe filtry</p>
              <div className="apartments-listing__filtering-row">
                <button
                  className={`basic${
                    apartmentFilter.promotion ? " current" : ""
                  }`}
                  onClick={() =>
                    setApartmentFilter(prevFilters => ({
                      ...prevFilters,
                      promotion: !prevFilters.promotion,
                    }))
                  }
                >
                  Promocja
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="apartments-listing__results">
          <div className="apartments-listing__results-spacer" />
          <div className="apartments-listing__results-sticky">
            <div className="apartments-listing__results-header">
              <div className="row flex-nowrap">
                {SORT_OPTIONS.map(option => {
                  const type = option.type
                  const label = option.label

                  return (
                    <div key={type ?? label} className="col">
                      <button
                        className="d-flex align-items-center justify-content-center text-center"
                        type="button"
                        disabled={!type}
                        onClick={type ? () => handleSorting(type) : undefined}
                      >
                        <span>
                          {label}
                          {type === sorting.type && (
                            <span
                              className={classnames(
                                "apartments-listing__results-header-arrow",
                                {
                                  "apartments-listing__results-header-arrow--rotate":
                                    sorting.order === "desc",
                                },
                                "d-flex align-items-center justify-content-center"
                              )}
                            >
                              <img
                                src={
                                  require("assets/icons/arrow-sort.svg").default
                                }
                                alt=""
                              />
                            </span>
                          )}
                        </span>
                      </button>
                    </div>
                  )
                })}
                <div className="col"></div>
              </div>
            </div>
          </div>

          {sortedApartments && sortedApartments.length !== 0 ? (
            sortedApartments.map((item, index) => {
              return (
                <Fade cascade triggerOnce key={index}>
                  <div className="apartments-listing__results-item">
                    <Link
                      className="apartments-listing__results-link"
                      to={item.uri}
                    />
                    <div className="row align-items-center" key={index}>
                      <div className="col">
                        {item.acfApartment.apartmentNumber}
                      </div>
                      <div className="col">{item.acfApartment.roomsnumber}</div>
                      <div className="col">
                        {item.acfApartment.area.toString().replace(".", ",")} m
                        <sup>2</sup>
                      </div>
                      <div className="col">
                        {item.acfApartment.floor ? (
                          item.acfApartment.floor === -1 ? (
                            <>
                              <span className="d-none d-md-block">
                                Parter niski
                              </span>
                              <span className="d-md-none">PN</span>
                            </>
                          ) : (
                            item.acfApartment.floor
                          )
                        ) : (
                          <>
                            <span className="d-none d-md-block">
                              Parter wysoki
                            </span>
                            <span className="d-md-none">PW</span>
                          </>
                        )}
                      </div>
                      <div className="col">{item.acfApartment.location}</div>
                      <div className={`col ${item.acfApartment.status}`}>
                        <div className={`status-full`}>
                          {item.acfApartment.status === "available"
                            ? "dostępne"
                            : item.acfApartment.status === "reserved"
                            ? "zarezerwowane"
                            : "sprzedane"}
                        </div>
                        <div className={`status-icons`}>
                          {item.acfApartment.status === "available" ? (
                            <span className={`available`}>D</span>
                          ) : item.acfApartment.status === "reserved" ? (
                            <span className={`reserved`}>Z</span>
                          ) : (
                            <span className={`sold`}>S</span>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        {item.acfApartment.dollhouseView && (
                          <button
                            onClick={e => {
                              e.preventDefault()
                              setDollyHouseImage(
                                item.acfApartment.dollhouseView
                              )
                            }}
                            href={item.acfApartment.dollhouseView}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={IcoView} alt="" />
                          </button>
                        )}
                      </div>
                      <div className="col">
                        <Link to="/kontakt/">
                          <img src={IcoEnvelope} alt="" />
                        </Link>
                      </div>
                      <div className="col">
                        <img src={IcoArrow} alt="" />
                      </div>
                    </div>
                  </div>
                </Fade>
              )
            })
          ) : (
            <p className="apartments-listing__results-info">
              <br />
              Brak mieszkań spełniających wybrane kryteria. Zmień parametry
              wyszukiwania lub <Link to="/kontakt/">
                skontaktuj się z nami
              </Link>{" "}
              - chętnie pomożemy w dokonaniu najlepszego wyboru.
            </p>
          )}
        </div>
      </div>
      {!!dollyHouseImage && (
        <DollyHousePopup
          dollyHouseImage={dollyHouseImage}
          setDollyHouseImage={setDollyHouseImage}
        />
      )}
    </section>
  )
}

export default withCookies(Listing)
